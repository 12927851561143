<template>
  <header
    dir="ltr"
    class="mb:items-center sticky top-0 z-30 flex flex-col items-start justify-between bg-[#EFECFF] px-16 py-12 font-inter md:h-64 md:flex-row"
  >
    <div class="flex gap-8 text-14 font-normal md:gap-12">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="size-20 shrink-0 md:size-24"
      >
        <path
          d="M5.32938 19.4201C5.21938 19.4201 5.09938 19.3901 4.99938 19.3401L2.77938 18.2301C2.52938 18.1001 2.36937 17.8401 2.36937 17.5601V14.7801C2.36937 14.3701 2.70937 14.0301 3.11937 14.0301C3.52937 14.0301 3.86937 14.3701 3.86937 14.7801V17.0901L5.66938 18.0001C6.03938 18.1801 6.18938 18.6301 5.99938 19.0001C5.86937 19.2601 5.60938 19.4201 5.32938 19.4201Z"
          fill="#4329CC"
        />
        <path
          d="M11.9994 22.7501C11.9394 22.7501 11.8794 22.7401 11.8294 22.7301C11.7694 22.7201 11.7094 22.6901 11.6494 22.6601L9.43937 21.5601C9.06938 21.3701 8.91938 20.9201 9.09938 20.5501C9.28938 20.1801 9.73938 20.0301 10.1094 20.2101L11.2494 20.7801V19.2201C11.2494 18.8101 11.5894 18.4701 11.9994 18.4701C12.4094 18.4701 12.7494 18.8101 12.7494 19.2201V20.7801L13.8894 20.2101C14.2594 20.0301 14.7094 20.1801 14.8994 20.5501C15.0894 20.9201 14.9394 21.3701 14.5594 21.5601L12.3494 22.6601C12.2894 22.6901 12.2294 22.7101 12.1694 22.7301C12.1094 22.7401 12.0594 22.7501 11.9994 22.7501Z"
          fill="#4329CC"
        />
        <path
          d="M18.6694 19.4201C18.3994 19.4201 18.1294 19.2701 17.9994 19.0101C17.8094 18.6401 17.9594 18.1901 18.3394 18.0001L20.1494 17.1001V14.7901C20.1494 14.3801 20.4894 14.0401 20.8994 14.0401C21.3094 14.0401 21.6494 14.3801 21.6494 14.7901V17.5701C21.6494 17.8501 21.4894 18.1101 21.2394 18.2401L18.9994 19.3401C18.8894 19.3901 18.7794 19.4201 18.6694 19.4201Z"
          fill="#4329CC"
        />
        <path
          d="M11.9994 14.4201C11.5894 14.4201 11.2494 14.0801 11.2494 13.6701V11.3601L9.43937 10.4601C9.06938 10.2701 8.91938 9.82006 9.09938 9.45006C9.28938 9.08006 9.73938 8.93006 10.1094 9.11006L11.9994 10.0501L13.8894 9.11006C14.2594 8.93006 14.7094 9.07006 14.8994 9.45006C15.0894 9.82006 14.9394 10.2701 14.5594 10.4601L12.7494 11.3601V13.6701C12.7494 14.0801 12.4094 14.4201 11.9994 14.4201Z"
          fill="#4329CC"
        />
        <path
          d="M3.10938 9.97006C2.69938 9.97006 2.35938 9.63006 2.35938 9.22006V6.45006C2.35938 6.39006 2.36938 6.33006 2.37938 6.28006C2.39938 6.19006 2.42938 6.12006 2.46938 6.05006C2.49938 6.00006 2.53938 5.96006 2.57938 5.92006C2.61938 5.88006 2.66938 5.84006 2.71938 5.81006C2.73938 5.80006 2.76938 5.78006 2.78938 5.77006L4.99938 4.66006C5.36938 4.48006 5.81938 4.63006 5.99938 5.00006C6.18938 5.37006 6.03938 5.82006 5.66938 6.00006L4.78938 6.44006L5.66938 6.88006C6.03938 7.07006 6.18938 7.52006 5.99938 7.89006C5.81938 8.26006 5.36938 8.41006 4.99938 8.23006L3.85938 7.66006V9.22006C3.85938 9.64006 3.52937 9.97006 3.10938 9.97006Z"
          fill="#4329CC"
        />
        <path
          d="M14.2194 3.86006C14.1094 3.86006 13.9894 3.83006 13.8894 3.78006L11.9994 2.84006L10.1094 3.78006C9.73938 3.97006 9.28937 3.82006 9.10938 3.45006C8.91937 3.08006 9.06938 2.63006 9.43937 2.44006L11.6594 1.33006C11.8694 1.22006 12.1194 1.22006 12.3294 1.33006L14.5494 2.44006C14.9194 2.63006 15.0694 3.08006 14.8894 3.45006C14.7594 3.71006 14.4994 3.86006 14.2194 3.86006Z"
          fill="#4329CC"
        />
        <path
          d="M20.8894 9.97006C20.4794 9.97006 20.1394 9.63006 20.1394 9.22006V7.66006L18.9994 8.23006C18.6294 8.41006 18.1794 8.26006 17.9994 7.89006C17.8094 7.52006 17.9594 7.07006 18.3394 6.88006L19.2194 6.44006L18.3294 6.00006C17.9594 5.82006 17.8094 5.37006 17.9994 5.00006C18.1894 4.63006 18.6294 4.48006 18.9994 4.66006L21.2094 5.76006C21.2294 5.77006 21.2594 5.78006 21.2794 5.80006C21.3394 5.83006 21.3894 5.88006 21.4394 5.93006C21.4694 5.97006 21.4994 6.01006 21.5294 6.05006C21.5694 6.12006 21.5994 6.19006 21.6194 6.27006C21.6294 6.33006 21.6394 6.39006 21.6394 6.44006V9.21006C21.6394 9.64006 21.2994 9.97006 20.8894 9.97006Z"
          fill="#4329CC"
        />
      </svg>
      <div>
        <h1 class="font-medium">
          Sandbox Mode
        </h1>
        <p class="text-[#818A9C]">
          <span class="hidden xl:inline-block">You are currently in Sandbox mode.</span>
          <span class="mb-4 ml-[-26px] mt-[6px] block text-[13px] md:m-0 md:inline-flex md:text-14">
            Test cards are available&nbsp;<a
              class="!text-[#4329CC]"
              :href="TEST_CARDS_URL"
              target="_blank"
            >here.</a>
          </span>
        </p>
      </div>
    </div>
    <div class="flex flex-col md:flex-row">
      <WebSelect
        :model-value="activeLanguage"
        :options="languages"
        :page-options="PAGE_OPT"
        no-border
        no-focus-shadow
        show-search
        selected-options-comes-first
        options-native-tooltip
        class="tw-web-select"
        name="language"
        text-left="Language:"
        search-placeholder="Search"
        :style-object="STYLE_OBJ"
        :disabled="isRedirecting"
        @selected="handleLanguageChange"
      />
      <WebSelect
        :model-value="activeCountry"
        :options="filteredCountries"
        :page-options="PAGE_OPT"
        no-border
        no-focus-shadow
        show-search
        selected-options-comes-first
        options-native-tooltip
        class="tw-web-select"
        name="country"
        text-left="Country:"
        search-placeholder="Search"
        :style-object="STYLE_OBJ"
        :disabled="isRedirecting"
        @selected="handleCountryChange"
      />
    </div>
  </header>

  <slot />
</template>

<script lang="ts" setup>
import { useSiteData } from '@/composables/useSiteData';
import { getSandboxUrl } from '@/utils/getBaseUrl';
import type { PageOptions } from '@shared/types/model';
import WebSelect from '@shared/components/select/index.vue';
import { $wait } from '@shared/utils/wait';

const PAGE_OPT = {
  colors: { theme: ['#EFECFF'], text: ['', '', '#4329CC'] }
} as PageOptions;

const STYLE_OBJ = {
  backgroundColor: 'transparent'
};

const TEST_CARDS_URL = 'https://docs.zotlo.com/getting-started/managing-sales-sites/sandbox-mode/test-payment-cards';

const { languages, countries, language, countryCode, restrictedCountries } = useSiteData();
const activeLanguage = ref(language.value);
const activeCountry = ref(countryCode.value);

const isRedirecting = computed(() => $wait.is(['languageChange', 'countryChange']));
const filteredCountries = computed(() => {
  return countries.value?.map((country) => {
    const code = country.value?.toLowerCase();
    if (restrictedCountries.value?.includes(code)) return { ...country, disabled: true };
    return country;
  })
});

function handleLanguageChange(language: Record<string, any>) {
  try {
    $wait.start('languageChange');
    const sandboxUrl = getSandboxUrl({ lang: language?.value, country: activeCountry.value });
    if (globalThis.location.href) globalThis.location.href = sandboxUrl;
  } finally {
    $wait.end('languageChange');
  }
}

function handleCountryChange(country: Record<string, any>) {
  try {
    $wait.start('countryChange');
    const sandboxUrl = getSandboxUrl({ lang: activeLanguage.value, country: country?.value });
    if (globalThis.location.href) globalThis.location.href = sandboxUrl;
  } finally {
    $wait.end('countryChange');
  }
}
</script>

<style lang="postcss" scoped>
.tw-web-select {
  @apply !mb-0 inline-flex md:inline !min-w-[100px] max-w-full md:max-w-[240px] lg:max-w-full;
}
:deep() {
  .tw-select-normal {
    @apply bg-transparent px-0 md:px-8 -ml-8 md:ml-0 h-32 py-0 md:py-8 md:h-40 border-transparent cursor-default;
    &.noFocusShadow {
      @apply focus-within:border-transparent;
    }
    .tw-selections {
      @apply flex-nowrap;
      &__text {
        @apply text-[#151B26] mr-8 text-[13px] md:text-14;
        &-left {
          @apply pr-0 text-[13px] md:text-14;
        }
      }
    }
  }

  .tw-select-disabled {
    @apply cursor-not-allowed;
    .tw-selections {
      &__text {
        @apply text-[#C3C9D5];
        &-left {
          @apply text-[#C3C9D5];
        }
      }
    }
  }
}
</style>
